export const sortCoveredCallStrategies = (strategies, sort) => {
    let sortedStrategies = strategies;
    switch(sort) {
        case 'expired':
            sortedStrategies = strategies.sort((a, b) => {
                const aDate = parseInt(a.fields["DaysToExpire"]);
                const bDate = parseInt(b.fields["DaysToExpire"]);
                return aDate - bDate;
            });
            break;
        case 'capital':
            sortedStrategies = strategies.sort((a, b) => {
                const aPrice = parseFloat(a.fields["SpotPrice"]);
                const bPrice = parseFloat(b.fields["SpotPrice"]);
                return aPrice - bPrice;
            });
            break;
        case 'profit':
            sortedStrategies = strategies.sort((a, b) => {
                const aProfit = (parseFloat(a.fields["PremiumCredit"]) / parseFloat(a.fields["SpotPrice"])).toFixed(2);
                const bProfit = (parseFloat(b.fields["PremiumCredit"]) / parseFloat(b.fields["SpotPrice"])).toFixed(2);
                return bProfit - aProfit;
            });
            break;
        case 'risk':
            sortedStrategies = strategies.sort((a, b) => {
                const aRisk = parseFloat(a.fields["PossibilityToWin"]);
                const bRisk = parseFloat(b.fields["PossibilityToWin"]);
                return bRisk - aRisk;
            });
            break;
    }
    return sortedStrategies;
}