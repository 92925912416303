/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
// reactstrap components
// import {
//   DropdownMenu,
//   DropdownItem,
//   DropdownToggle,
//   UncontrolledDropdown
// } from "reactstrap";
// core components
import { getLiveOptionsActivities } from "actions/Benzinga";
import { getNewOptionsActivities } from "reducers/Benzinga/liveOptionsFlow";
import { optionActivityCell } from "../optionsFlow/OptionActivityCell";
import { TransitionGroup } from 'react-transition-group';

function NewActivitiesCells(props) {
    const [newFeeds, updateNewFeeds] = React.useState([]);
    const {
        last_updated_timestamp,
        getNewOptionsActivities: fetchNewData
    } = props;

    const TWENTY_SECS = 10000;

    const getNewLiveOptionsActivities = () => {
        let lastTime;
        let lastUpdatedTimestamp = last_updated_timestamp;
        async function ping(currentTime) {
              if (!lastTime || currentTime - lastTime >= TWENTY_SECS) {
                  lastTime = currentTime;
                  const data = await getLiveOptionsActivities({ lastUpdatedTimestamp: lastUpdatedTimestamp });
                  const count = data.option_activity.length;
                  if (count) {
                    lastUpdatedTimestamp = data.option_activity[count-1].updated;
                    /**
                     * Mock server
                     */
                    // const maxFeeds = Math.floor(Math.random() * Math.floor(3));
                    // const randomStopIndex = Math.floor(Math.random() * Math.floor(data.option_activity.length - maxFeeds));
                    /**
                     * END 
                     */
                    // const newFeeds = data.option_activity.slice(randomStopIndex, randomStopIndex + maxFeeds);
                    const newFeedData = data.option_activity;
                    updateNewFeeds(newFeedData);
                    fetchNewData(newFeedData, lastUpdatedTimestamp);
                  }
              }
            window.requestAnimationFrame(ping);
        }
        window.requestAnimationFrame(ping);
    }

    React.useEffect(() => {
      getNewLiveOptionsActivities();
    }, []);

    const OptionActivityCells = React.useCallback(() => {
        if (newFeeds && newFeeds.length) {
            return newFeeds.map((activity) => {
              return optionActivityCell(activity);
            });
        } 
        return null;
    }, [newFeeds])

  return (
    <TransitionGroup
      component={OptionActivityCells}
    >
    </TransitionGroup>
  );
}

const mapStateToProps = state => ({
    last_updated_timestamp: state.Benzinga.last_updated_timestamp
  });
  
const mapDispatchToProps = dispatch => ({
    getNewOptionsActivities: (newActivities, last_updated_timestamp) => dispatch(getNewOptionsActivities({newActivities, last_updated_timestamp}))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(NewActivitiesCells);
