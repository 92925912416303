import React from "react";
// reactstrap components
import {
  Progress,
  Tooltip,
} from "reactstrap";

export function RiskProgressBar({
  id,
  name,
  isSuccess,
  potentialToWin,
  profitability
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const type = isSuccess ? `text-success` : `text-warning`;
  const text = isSuccess ? `${profitability}%` : `$${profitability}`;
  const tooltipText = isSuccess ? `${potentialToWin}% chance will make ${profitability}% profits`
                      : `${potentialToWin}% chance will lose $${profitability}`;
  return (
    <>
      <div>
        <div className={`${type} small cursor`} id={id}>{name}
          <span className="icon icon-xs icon-shape text-white">
            <i class="fas fa-question-circle"></i>
          </span>
        </div>
        <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggle}>
          {tooltipText}
        </Tooltip>
      </div>
      <div className={`${type} small`}>{text}</div>
      <Progress
          className="progress-xs my-2"
          max="100"
          value={potentialToWin}
          color={isSuccess ? `success`: `warning`}
      />
    </>
  )
}