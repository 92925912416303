import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

import { connect } from 'react-redux';

import moment from 'moment';
import { RiskProgressBar } from "./RiskProgressBar";
import { getTickerID, getRealTimeData } from "actions/webull";

function StrategyCard({
  strategy,
  index
}) {
  const {
    Ticker,
    SpotPrice,
    StrikePrice,
    PremiumCredit,
    Expired,
    PossibilityToWin
  } = strategy;

  const strikePrice = parseFloat(StrikePrice).toFixed(2);
  const spotPrice = parseFloat(SpotPrice).toFixed(2);
  const premium = (parseFloat(PremiumCredit) * 100).toFixed(2);
  const potentialToWin = (parseFloat(PossibilityToWin) * 100).toFixed(0);
  const profitability = (premium / strikePrice).toFixed(2);
  const maximumLoss = (spotPrice * 100 - premium).toFixed(2);
  const className = "bg-gradient-default";
  const expiredDate = moment(Expired, "YYYY-MM-DD");
  const daysToExpire = moment().to(expiredDate);
  const [stockPrice, setStockPrice] = React.useState(0);
  const [change, setChange] = React.useState(0);

  const getLatestStockPrice = async () => {
    const webullTickerInfo = await getTickerID({ ticker: Ticker }); 
    const webullTickerId = await webullTickerInfo && webullTickerInfo.data && webullTickerInfo.data[0].tickerId;
    if (webullTickerId) {
      const tickerPriceData = await getRealTimeData({ tickerId: webullTickerId, type: 'd1', count: 1 });
      const closePrice = tickerPriceData.close;
      const changePercent = parseFloat(tickerPriceData.change);
      setStockPrice(closePrice);
      setChange(changePercent);
    }
  }

  React.useEffect(() => {
    getLatestStockPrice();
  }, [Ticker]); 

    return (
      <Col xl="3" md="6" sm="12" key={index}>
        <Card className={className}>
          <CardBody>
            <div className="mb-2">
              <div className="d-flex">
                  <div className="h2 text-white">
                    {Ticker}
                    <span className="pl-4 text-success text-sm">${stockPrice}</span>
                    {change >= 0 ? 
                        (<span className="pl-2 text-success text-sm">({change}%)</span>) :
                        (<span className="pl-2 text-danger text-sm">({change}%)</span>)
                      }
                  </div>
              </div>
              <div className="text-light mt-2 text-sm">
                Stock purchase price
              </div>
              <div>
                <span className="text-success font-weight-600">
                  ${spotPrice} 
                </span>{" "}
                <span className="text-light">x 100 shares</span>
              </div>
              <div className="text-white mt-4">
                Sell a Call @ <span className="text-success h3">${strikePrice}</span> 
              </div>
              <div>
                <span className="text-success font-weight-600">
                  Credit: ${premium}
                </span>{" "}
              </div>
              <div className="text-white mt-4">Expire on {expiredDate.format('L')}</div>
              <span className="text-light text-sm">
                Expire {daysToExpire}
              </span>
            </div>
          </CardBody>
          <CardBody>
          <Row>
              <Col xl="6">
              <RiskProgressBar
                name="Potential profits:"
                isSuccess={true}
                potentialToWin={potentialToWin}
                profitability={profitability}
                id={`${Ticker}-${Expired}-${index}-success`}
              />
              </Col>
              <Col xl="6">
                <RiskProgressBar
                  name="Maximum Loss:"
                  isSuccess={false}
                  potentialToWin={100-potentialToWin}
                  profitability={maximumLoss}
                  id={`${Ticker}-${Expired}-${index}-failure`}
                />
              </Col>
          </Row>
        </CardBody>
        </Card>
      </Col>
    )
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});
  
export default connect(mapStateToProps, mapDispatchToProps)(StrategyCard);