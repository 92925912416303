// const GOOGLE_CLOUD_ENDPOINT = 'http://localhost:5001/easy-options-dev/us-central1';
const GOOGLE_CLOUD_ENDPOINT = 'https://us-central1-easy-options-dev.cloudfunctions.net';

export const getStonksFromAirtable = async ({ date }) => {
    const endpoint = `${GOOGLE_CLOUD_ENDPOINT}/airtableDailyStonks?date=${date}`;
    const response = await fetch(`${endpoint}`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        },
    });
    if (response && response.status == 200) {
        return response.json();
    } else {
        // TODO: error message
    }
    // const options = {
    //     headers: new Headers({
    //         'Cache-Control': 'max-age=20'
    //     })
    // }
    // if ('caches' in window) {
    //     const cacheName    = `easyOptions-dailyStonk-${date}`;
    //     let cachedData     = await getCachedData( cacheName, endpoint );
     
    //     if ( cachedData ) {
    //        console.log( 'Retrieved cached data' );
    //        return cachedData;
    //     }
     
    //     console.log( 'Fetching fresh data' );
     
    //     const cacheStorage = await caches.open( cacheName );
    //     await cacheStorage.add(new Request(endpoint, options));
    //     cachedData = await getCachedData( cacheName, endpoint );
    //     await deleteOldCaches( cacheName );
     
    //     return cachedData;
    // }
}

export const getCoveredCallsFromAirtable = async ({ date, hasSubscription }) => {
    const endpoint = `${GOOGLE_CLOUD_ENDPOINT}/airtableCoveredCalls?date=${date}`;
    const response = await fetch(`${endpoint}`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        },
    });
    if (response && response.status == 200) {
        const result = await response.json();
        if (result && !hasSubscription) {
            return result.slice(0, 2);
        }
        return result;
    } else {
        // TODO: error message
    }
    // const options = {
    //     headers: new Headers({
    //         'Cache-Control': 'max-age=20'
    //     })
    // }
    // if ('caches' in window) {
    //     const cacheName    = `easyOptions-coveredCalls-${date}`;
    //     let cachedData     = await getCachedData( cacheName, endpoint );
     
    //     if ( cachedData ) {
    //        console.log( 'Retrieved cached data' );
    //        return cachedData;
    //     }
     
    //     const cacheStorage = await caches.open( cacheName );
    //     await cacheStorage.add(new Request(endpoint, options));
    //     cachedData = await getCachedData( cacheName, endpoint );
    //     await deleteOldCaches( cacheName );
     
    //     return cachedData;
    // }
}