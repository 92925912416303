/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

function Pricing() {
  return (
    <>
      <AuthHeader title="Let Easy Options be your investment friend" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="12">
            <div className="pricing card-group flex-column flex-md-row mb-3">
              <Card className="card-pricing border-0 text-center mb-4">
                <CardHeader className="bg-transparent">
                  <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                    Strategies
                  </h4>
                </CardHeader>
                <CardBody className="px-lg-7">
                  <div className="display-2">$8</div>
                  <div className="text-through">original price $19</div>
                  <span className="text-muted">per month</span>
                  <ul className="list-unstyled my-4">
                    <li>
                      <div className="d-flex justify-content-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-dollar-sign" />
                          </div>
                          <span className="pl-2">
                            Covered Call Strategies
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Button className="mb-3" color="info" type="button" href="/auth/signup">
                    Sign Up
                  </Button>
                </CardBody>
                <CardFooter>
                  Learn more about how to make weekly passive incomes.
                </CardFooter>
              </Card>
              <Card className="card-pricing bg-gradient-primary zoom-in shadow-lg rounded border-0 text-center mb-4">
                <CardHeader className="bg-transparent">
                  <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                    Data
                  </h4>
                </CardHeader>
                <CardBody className="px-lg-7">
                  <div className="display-1 text-white">$35</div>
                  <div className="text-through text-white">original price $49</div>
                  <span className="text-white">per month</span>
                  <ul className="list-unstyled my-4">
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-dollar-sign" />
                        </div>
                        <div>
                          <span className="pl-2 text-white">
                            Options Order Flow (valued $99/month)
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Button className="mb-3" color="secondary" type="button" href="/auth/signup">
                    Start 7 days free trial
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Pricing;
