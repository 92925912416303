export const LIVE_OPTIONS_FLOW = 'LIVE_OPTIONS_FLOW';
export const NEW_OPTIONS_ACTIVITIES = 'NEW_OPTIONS_ACTIVITIES';

export const getLiveOptionsFlow = ({option_activity, last_updated_timestamp}) => ({
    type: LIVE_OPTIONS_FLOW,
    option_activity,
    last_updated_timestamp
});

export const getNewOptionsActivities = ({newActivities, last_updated_timestamp}) => ({
    type: NEW_OPTIONS_ACTIVITIES,
    newActivities,
    last_updated_timestamp
});
