const GOOGLE_CLOUD_ENDPOINT = 'https://us-central1-easy-options-dev.cloudfunctions.net';
// const GOOGLE_CLOUD_ENDPOINT = 'http://localhost:5001/easy-options-dev/us-central1';

export const createStripeCustomer = async ({ email, uid }) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/createStripeCustomer';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            email,
            uid
        })
    });
    if (response) {
        console.log(response.data);
        return response.data;
    } else {
        console.log(response);
    }
}

export const getPaymentMethods = async ({ customer_id }) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/getPaymentMethods';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            customer_id,
            type: 'card'
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}

export const attachPaymentMethodToCustomer = async ({payment_id, customer_id}) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/attachPaymentMethodToCustomer';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            customer_id,
            payment_id
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}

export const createPaymentMethod = async ({ card }) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/createPaymentMethod';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            card,
            type: 'card'
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}

export const getPrices = async () => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/getPrices';
    const response = await fetch(`${endpoint}`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors'
    });
    if (response.ok) {
        return response.json();
    } else {
        // TODO: error message
    }
}

export const createSubscription = async ({ customer_id, items }) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/createSubscription';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            customer_id,
            items
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}

export const getSubscription = async ({ subscription_id }) => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/getSubscription';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            subscription_id
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}

export const createStripePayment = async () => {
    const endpoint = GOOGLE_CLOUD_ENDPOINT + '/createStripePayment';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'no-cors',
        body: JSON.stringify({})
    });
    if (response) {
        console.log(response.data);
        return response.data;
    } else {
        // TODO: error message
    }
}

export const getCustomer = async (customer_id) => {
    const endpoint =  GOOGLE_CLOUD_ENDPOINT + '/getCustomer';
    const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            customer_id
        })
    });
    if (response.ok) {
        return await response.json();
    } else {

    }
}