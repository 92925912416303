/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

function CoveredCallHeader({name, parentName, sorting, hasSubscription}) {
  const onFilter = (e) => {
    const sortingType = e.target.dataset["sort"];
    sorting(sortingType);
  }
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {
                hasSubscription ? (
                  <Col className="text-right" lg="6" xs="5">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="neutral"
                        className="mr-0"
                      >
                        Sort By
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          href="#pablo"
                          onClick={onFilter}
                        >
                          <i class="far fa-calendar-alt text-primary"></i> 
                          <span className="ml-1" data-sort={'expired'}> Expiration Date </span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={onFilter}
                        >
                          <i class="fa fa-piggy-bank text-info"></i>
                          <span data-sort={'capital'}> Required least capital </span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={onFilter}
                        >
                          <i class="fa fa-money-bill-wave text-success"></i>
                          <span data-sort={'profit'}> Most profits </span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={onFilter}
                        >
                          <i class="far fa-surprise text-danger"></i>
                          <span className="ml-1" data-sort={'risk'}>Least Risk</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                ) : null
              }
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CoveredCallHeader;
