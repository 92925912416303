// const WEBULL_QUOTE_HOST = 'https://quotes-gw.webullfintech.com/api';
// const QUOTE_SEARCH_URL = `${WEBULL_QUOTE_HOST}/search/pc/tickers`;
const BACKEND_API_HOST = 'http://54.227.78.62/api';
// const BACKEND_API_HOST = 'http://localhost:8001/api';

export const getTickerID = async ({ ticker }) => {
    // TODO: pagination
    const response = await fetch(`${BACKEND_API_HOST}/getTickerId?ticker=${ticker}`, {
        method: 'GET'
    });
    if (response && response.status == 200) {
        return response.json();
    } else {
        // TODO: error message
    }
}

export const getDailyData = async ({ tickerId, type, count }) => {
    const response = await fetch(`${BACKEND_API_HOST}/getDailyData?tickerId=${tickerId}&type=${type}&count=${count}`, {
        method: 'GET'
    });
    if (response && response.status == 200) {
        return response.json();
    } else {
        // TODO: error message
    }
}

export const getRealTimeData = async({ tickerId }) => {
    const response = await fetch(`${BACKEND_API_HOST}/getRealTimeData?tickerId=${tickerId}`, {
        method: 'GET'
    });
    if (response && response.status == 200) {
        return response.json();
    } else {
        // TODO: error message
    }
}