import { LIVE_OPTIONS_FLOW, NEW_OPTIONS_ACTIVITIES } from './liveOptionsFlow';
  
const updatedOptionsActivities = ({originalOptionsActivities, incomingOptionsActivities}) => {
    const combined = [...originalOptionsActivities, ...incomingOptionsActivities];
    const distinctActivities = Array.from(new Set(combined.map(activity => activity.id))).map(
        id => {
            return combined.find(activity => activity.id === id);
        }
    );
    return distinctActivities.sort((a, b) => b.updated - a.updated);
}

export default function reducer(
    state = {
        option_activity: [],
        last_updated_timestamp: 0,
        new_activities: [],
    },
    action
) {
    switch (action.type) {
    case LIVE_OPTIONS_FLOW:
        return {
            ...state,
            option_activity: [...state.option_activity, ...action.option_activity].sort((a, b) => b.updated - a.updated),
            last_updated_timestamp: action.last_updated_timestamp
        };
    case NEW_OPTIONS_ACTIVITIES:
        return {
            ...state,
            new_activities: action.newActivities,
            option_activity: [...updatedOptionsActivities({
                originalOptionsActivities: state.option_activity,
                incomingOptionsActivities: action.newActivities
            })],
            last_updated_timestamp: action.last_updated_timestamp
        }
    default:
        break;
    }
    return state;
}