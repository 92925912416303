/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import CardsHeader from "components/Headers/CardsHeader.js";
import { connect } from 'react-redux';
import { db } from "services/firebase";
import { getPaymentMethods, createPaymentMethod, attachPaymentMethodToCustomer, createStripeCustomer } from "actions/payments";
import { Subscriptions } from './Subscriptions';
import { Redirect } from "react-router-dom";
import { getSubscription as getSubReducer } from "reducers/EasyOptions/user";
import moment from 'moment';

function Setting({ user, subscription, tiers }) {
  const [cardNumber, setcardNumber] = React.useState('');
  const [expireDate, setExpireDate] = React.useState('');
  const [cvc, setcvc] = React.useState('');
  const [currentPrice, setCurrentPrice] = React.useState(0);
  const [userPayments, setUserPayments] = React.useState([]); 
  const [newPaymentId, setNewPaymentId] = React.useState('');
  const [currentCustomerId, setCustomerId] = React.useState(null);
  const [creditCardReadOnly, setCreditCardReadOnly] = React.useState(false);
  const periodEndRaw = subscription && subscription.current_period_end;
  const periodEndOn = periodEndRaw && moment(periodEndRaw * 1000).format("MMM DD, YYYY");
  const retrievePaymentMethods = async (customer_id) => {
      // Handle error state
    const result = await getPaymentMethods({ customer_id });
    setUserPayments(result.data);
    if (result.data.length) {
        setCreditCardReadOnly(true);
        const card = result.data[0].card;
        setcardNumber(`****-****-****-${card.last4}`);
        setExpireDate(`${card.exp_month}/${card.exp_year}`);
        setCreditCardReadOnly(true)
    }
  }

  React.useEffect(() => {
    const userRef = db.collection('stripe_customers').doc(user.uid);
    userRef.get().then((doc) => {
        if (doc.exists) {
            const customer = doc.data();
            setCustomerId(customer.customer_id);
            retrievePaymentMethods(customer.customer_id);
        } else {
          db.collection('stripe_customers').doc(user.uid).onSnapshot((snapshot2) => {
            if (!snapshot2.data()) {
              createStripeCustomer({
                email: user.email,
                uid: user.uid
              });
            }
          });
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }, [user]);

  const updateCreditCard = React.useCallback( async () => {
    const expireDateFragment = expireDate.split('/');
    if (expireDateFragment.length === 2) {
        const card = {
            number: cardNumber,
            exp_month: expireDateFragment[0],
            exp_year: expireDateFragment[1],
            cvc: cvc
        }
        const result = await createPaymentMethod({ card });
        setNewPaymentId(result.id);
        const attachedResult = await attachPaymentMethodToCustomer({ customer_id: currentCustomerId, payment_id: result.id});
        console.log(attachedResult);
    }
  }, [currentCustomerId, newPaymentId, expireDate]);

  if (!user || !user.email) {
    return (<Redirect from="*" to="/auth/login" />);
  }

  return (
    <>
      <CardsHeader name="Payment" />
      <Container className="mt--6" fluid>
        <Row>
        <Col lg="4">
            <Subscriptions user={user} customer_id={currentCustomerId} userPayments={userPayments} tiers={tiers} periodEndOn={periodEndOn}/>
          </Col>
          <Col lg="4">
            <Card className="bg-gradient-primary">
              <CardBody>
                <Row className="justify-content-between align-items-center">
                  <div className="col text-white">
                      Credit Card Info
                  </div>
                  <Col className="col-auto">
                    <div className="d-flex align-items-center">
                      <small className="text-white font-weight-bold mr-3">
                        Update Payment
                      </small>
                      <div>
                        <label className="custom-toggle custom-toggle-white">
                          <input checked={!creditCardReadOnly} type="checkbox" onChange={(e) => setCreditCardReadOnly(!creditCardReadOnly)}/>
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Form className="form-primary" role="form">
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-alternative mb-3", {
                          focused: cardNumber,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-credit-card" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Card number"
                          type="text"
                          value={cardNumber}
                          onChange={(e) => setcardNumber(e.currentTarget.value)}
                          disabled={userPayments.length ? creditCardReadOnly : false}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames(
                              "input-group-alternative mb-3",
                              {
                                focused: expireDate,
                              }
                            )}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="MM/YY"
                              type="text"
                              value={expireDate}
                              onChange={(e) => setExpireDate(e.currentTarget.value)}
                              disabled={userPayments.length ? creditCardReadOnly : false}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-alternative", {
                              focused: cvc,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="CCV"
                              type="text"
                              value={cvc}
                              onChange={(e) => setcvc(e.currentTarget.value)}
                              disabled={userPayments.length ? creditCardReadOnly : false}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button block color="info" type="button" onClick={updateCreditCard} disabled={creditCardReadOnly}>
                      { userPayments.length ? "Update Payment" : "Save new card" }
                    </Button>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
const mapStateToProps = state => ({
    user: state.EasyOptions.user,
    subscription: state.EasyOptions.subscription,
    tiers: state.EasyOptions.tiers
  });
  
  const mapDispatchToProps = dispatch => ({
    getSub: ({subscription}) => dispatch(getSubReducer({subscription}))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Setting);
