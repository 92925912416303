/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

function CardsHeader({ name, parentName, stocks }) {
  const cards = () => {
    return stocks && stocks.map(stock => {
      const profitTextClass = stock.changePercentSinceAnnounced >= 0 ? "text-success" : "text-danger";
      const arrowDirection = stock.changePercentSinceAnnounced >= 0 ? "fa fa-arrow-up" : "fa fa-arrow-down";
      return (
        <Col md="6" xl="3">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  <div className="d-flex">
                    <span className="h4 font-weight-bold text-success">${stock.symbol}</span>
                    <span className="ml-2 mt-1 text-truncate">({stock.name})</span>
                  </div>
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  ${stock.latestPrice}
                </span>
                <div className="mt-4">
                  <small><b className="text-success">Buy In</b> price range</small>
                  <h4 className="mb-0">{stock.buyInRange}</h4>
                </div>
                <div>
                  <small><b className="text-warning">Exit</b> price range</small>
                  <h4 className="mb-0">{stock.exitRange} <span className="text-success">({stock.exitProfits})</span></h4>
                </div>
              </div>
              <Col className="col-auto">

              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm">
              <span className={`${profitTextClass} mr-2`}>
                <i className={arrowDirection} /> ${stock.changePercentSinceAnnounced}%
              </span>{" "}
              <span className="text-nowrap">Since ${stock.date}</span>
            </p>
          </CardBody>
        </Card>
      </Col>
      );
    });
  }
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
                { cards() }
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
