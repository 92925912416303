export const UPDATE_USER = 'UPDATE_USER';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_TIERS = 'GET_TIERS';

export const updateUser = ({user}) => ({
    type: UPDATE_USER,
    user
});

export const getSubscription = ({subscription}) => ({
    type: GET_SUBSCRIPTION,
    subscription
});

export const getTiers = ({tiers}) => ({
    type: GET_TIERS,
    tiers
});