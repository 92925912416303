/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
    CircularProgressbarWithChildren,
    CircularProgressbar,
    buildStyles
} from 'react-circular-progressbar';

const BreadcrumbSection = ({name, parentName}) => {
    return (
        <Col lg="6" xs="7">
            <h6 className="h2 d-inline-block mb-0">{name}</h6>{" "}
            <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links"
            >
                <BreadcrumbItem>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-home" />
                </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {parentName}
                </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                {name}
                </BreadcrumbItem>
            </Breadcrumb>
        </Col>
    );
}

function OptionsFlowHeader({
    name, parentName,
    callDollarAmount,
    bullishRate,
    putCallRatio,
    putDollarAmount,
    callFlow,
    putFlow
}) {
    const callFlowRatio = (callDollarAmount/(callDollarAmount + putDollarAmount)).toFixed(2);
    const putFlowRatio = (1 - callFlowRatio).toFixed(2);
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <BreadcrumbSection name={name} parentName={parentName} />
            </Row>

            <Row>
              <Col md="6" xl="3">
                  <CardBody className="header-card border-0 card bg-gradient-primary">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Flow Sentiment
                        </CardTitle>
                        <span className="h2 text-white font-weight-bold mb-0">
                          Bullish
                        </span>
                      </div>
                      <Col md="4" >
                           <CircularProgressbarWithChildren
                                value={bullishRate * 100}
                                strokeWidth={5}
                                styles={buildStyles({
                                    pathColor: "#2dce89",
                                    trailColor: "#adb5bd",
                                    textSize: 24
                                })}
                            >
                                <div style={{color: "white", fontSize: 24 }}>
                                    {bullishRate.toFixed(2) * 100}%
                                </div>
                            </CircularProgressbarWithChildren>
                      </Col>
                    </Row>
                  </CardBody>
              </Col>
              <Col md="6" xl="3">
                  <CardBody className="header-card border-0 card bg-gradient-info">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Put to Call
                        </CardTitle>
                        <span className="h2 text-white font-weight-bold mb-0">{putCallRatio}</span>
                      </div>
                      <Col md="4" >
                            <CircularProgressbarWithChildren
                                value={bullishRate * 100}
                                strokeWidth={5}
                                styles={buildStyles({
                                    pathColor: "#5603ad",
                                    trailColor: "#adb5bd",
                                    textSize: 24
                                })}
                            >
                                <div style={{color: "white", fontSize: 24 }}>
                                    {putCallRatio}
                                </div>
                            </CircularProgressbarWithChildren>
                      </Col>
                    </Row>
                  </CardBody>
              </Col>
              <Col md="6" xl="3">
                  <CardBody className="header-card border-0 card bg-gradient-danger">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Put Flow
                        </CardTitle>
                        <span className="h2 text-white font-weight-bold mb-0">{putFlow.toFixed(2)}</span>
                      </div>
                      <Col md="4" >
                            <CircularProgressbarWithChildren
                                value={bullishRate * 100}
                                strokeWidth={5}
                                styles={buildStyles({
                                    pathColor: "#ffd600",
                                    trailColor: "#adb5bd",
                                    textSize: 24
                                })}
                            >
                                <div style={{color: "white", fontSize: 24 }}>
                                    {putFlowRatio * 100} %
                                </div>
                            </CircularProgressbarWithChildren>
                      </Col>
                    </Row>
                  </CardBody>
              </Col>
              <Col md="6" xl="3">
                  <CardBody className="header-card border-0 card bg-gradient-default">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-white mb-0"
                        >
                          Call Flow
                        </CardTitle>
                        <span className="h2 text-white font-weight-bold mb-0">{callFlow.toFixed(2)}</span>
                      </div>
                      <Col md="4" >
                            <CircularProgressbarWithChildren
                                value={bullishRate * 100}
                                strokeWidth={5}
                                styles={buildStyles({
                                    pathColor: "rgba(37,211,102,1)",
                                    trailColor: "#adb5bd",
                                    textSize: 24
                                })}
                            >
                                <div style={{color: "white", fontSize: 24 }}>
                                    {callFlowRatio * 100} %
                                </div>
                            </CircularProgressbarWithChildren>
                      </Col>
                    </Row>
                  </CardBody>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

OptionsFlowHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  flowSentiment: PropTypes.number
};

export default OptionsFlowHeader;
