import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  NavLink
} from "reactstrap";
import { Link } from "react-router-dom";

export function SignUpCard () {
    return (
        <Col xl="3" md="6" sm="12">
            <Card className="card-pricing border-0 text-center mb-4 bg-gradient-default">
                <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                    Easy Options Strategies
                </h4>
                </CardHeader>
                <CardBody className="">
                <div className="display-2 text-info">$8</div>
                <span className="text-muted">per month</span>
                <div className="mt-4">
                    <Button
                        className="mb-3 p-2"
                        color="info"
                    >
                        <NavLink
                            to="/admin/settings?sub=2"
                            className="text-white"
                            tag={Link}
                        >
                            Sign up
                        </NavLink>
                    </Button>
                </div>
                <div className="h4 text-light">
                    Subscribe to get complete Covered Call Strategies experience
                </div>
                </CardBody>
            </Card>
        </Col>
    );
}