/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row
} from "reactstrap";

// core components
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import StrategyCard from "./strategies/StrategyCard";
import { getCoveredCallsFromAirtable } from "actions/airtable";
import CoveredCallHeader from "components/Headers/CoveredCallHeader";
import { sortCoveredCallStrategies } from "../coveredCalls/sortCoveredCallStrategies";
import { SignUpCard } from "../coveredCalls/signUpCard";

function CoveredCalls(props) {
    const [coveredCalls, setCoveredCalls] = React.useState([]);

    const {
        user,
        tiers
    } = props;

    // TODO: return to login page if user object doesn't exist

  const getCoveredCalls = async () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;
    const hasSubscription = tiers && tiers.length && tiers.indexOf(2) > -1;
    let strategies = await getCoveredCallsFromAirtable({ date: formattedDate, hasSubscription });
    setCoveredCalls(strategies);
  }

  React.useEffect(() => {
      getCoveredCalls();
  }, [tiers]);

  const RenderStrategyCards = React.useCallback(() => {
    const hasSubscription = tiers && tiers.length && tiers.indexOf(2) > -1;
    return (
        <Row>
        {
              coveredCalls.map((strategy, index) => {
                return (
                  <>
                    <StrategyCard
                      strategy={strategy.fields}
                      key={index}
                      index={index}
                    />
                  </>
                );
              })
        }
        { hasSubscription ? null : (<SignUpCard />) }
        </Row>
    )
  }, [tiers, coveredCalls]);

  

  const Header = React.useCallback(() => {
    const hasSubscription = tiers && tiers.length && tiers.indexOf(2) > -1;
    const sortStrategies = (sort) => {
      const strategies = sortCoveredCallStrategies(coveredCalls, sort);
      setCoveredCalls(strategies);
    }
    return (
      <CoveredCallHeader name="Covered Calls" parentName="Strategies" sorting={sortStrategies} hasSubscription={hasSubscription}/>);
  }, [tiers, coveredCalls]);

  if (!user || !user.email) {
    return (<Redirect from="*" to="/auth/login" />);
  }

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
          {
            coveredCalls.length ? <RenderStrategyCards /> : null
          }
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.EasyOptions.user,
  subscription: state.EasyOptions.subscription,
  tiers: state.EasyOptions.tiers
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CoveredCalls);