/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

function Profile() {
  return (
    <>
      <AuthHeader title="Let Easy Options be your investment friend" lead="" />
      <Container className="mt--8 pb-5">
        <Card className="card-profile">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                    </Col>
                    <Col className="order-lg-1" lg="4">
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Easy Options{" "}
                      <span className="font-weight-light">, LLC</span>
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Seattle, WA
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Easy Options makes stock and options trading easy
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top ">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p>
                          Easy Options will be integrated with Interative Brokers and Robinhood.
                          You can enjoy the lowest interest rates with Interactive Brokers' margin account,
                          but also enjoy the most intuitive app design to enhance your trading experience and performance.
                          You can sign up for our newsletter to learn about the active trading stocks daily
                          and learn about our daily options example trades.
                          More features are coming! Stay tuned!
                        </p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div className="h4 mt-4">
                          Mission
                        </div>
                        <p>
                        Lower hurdle to implementing analysis through seamless brokerage integration. 
                        Equip investors with powerful, yet intuitive, toolkits to identify and analyze potential investments and trade strategies.
                        </p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div className="h4 mt-4">
                         
                          Disclaimer
                        </div>
                        <p>
                        EasyOptions is NOT an investment advisor.
                        <br />
                        EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THE WEBSITE AND PRODUCTS AND SERVICES CONTAINED AND OFFERED ON THE WEBSITE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. NEITHER EASY OPTIONS, LLC NOR ANY OF OUR PARTNERS MAKES ANY WARRANTY THAT THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL THAT YOU PURCHASE OR OBTAIN FROM THE USE OF OUR SERVICES WILL MEET YOUR EXPECTATIONS OR THAT AND ANY ERRORS WILL BE IMMEDIATELY CORRECTED. NEITHER WE NOR ANY OF OUR PARTNERS MAKES ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO OUR WEBSITES, ANY CONTENT, OR ANY OF OUR SERVICES, TOOLS, PRODUCTS, OR PROPERTIES. YOU EXPRESSLY AGREE THAT YOU WILL ASSUME THE ENTIRE RISK AS TO THE QUALITY AND THE PERFORMANCE OF OUR SERVICES AND THE ACCURACY OR COMPLETENESS OF THEIR CONTENT.

                        SOME STATES DO NOT ALLOW EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY AND THAT OF OUR THIRD-PARTY CONTENT PROVIDERS WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. THIS DISCLAIMER OF WARRANTIES DOES NOT APPLY IN NEW JERSEY.
                        <br />
                        That was tiresome, but important.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
      </Container>
    </>
  );
}

export default Profile;
