import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getPrices, createSubscription } from "actions/payments";
import { db } from "services/firebase";
import moment from "moment";

export function Subscriptions({ user, customer_id, userPayments, tiers, periodEndOn }) {
    const [fee, setFee] = React.useState(0);
    const [prices, setPrices] = React.useState([]);
    const [subscriptionUpdateAlert, setAlert] = React.useState(null);
    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const dateToCharge = moment().add("days", 7).format("MMM DD, YYYY");

    const [subOptions, setSubOptions] = React.useState({
        2: tiers?.indexOf(2) > -1 || false,
        3: tiers?.indexOf(3) > -1 || false
    });

    const [subtotal, setSubtotal] = React.useState(fee);

    const updateSubs = (index) => {
        setSubOptions({
            ...subOptions,
            [index]: !subOptions[index]
        });
    };

    const fetchPrices = async () => {
        const result = await getPrices();
        setPrices(result.data);
    }

    React.useEffect(() => {
        fetchPrices();
    }, []);
    
    React.useMemo(() => {
        let sum = 0;
        let selectedPrices = [];
        for(let key in subOptions) {
            if (subOptions[key]) {
                if (key === "2") {
                    sum += 8;
                    const price = prices.filter((item) => item.unit_amount === 800);
                    selectedPrices.push(...price);
                }
                if (key === "3") {
                    sum += 35;
                    const price = prices.filter((item) => item.unit_amount === 3500);
                    selectedPrices.push(...price);
                }
            }
        }
        setSubtotal(sum);
        setSelectedProducts(selectedPrices);
    }, [subOptions]);

    const RenderSubOptions = React.useCallback(() => {
        return (
            <ul className="list-unstyled m-4">
            <li>
              <div className="d-flex align-items-center">
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input checked={subOptions[2]} type="checkbox" onChange={(e) => updateSubs(2)}/>
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <div>
                    <span className="pl-2 text-white">
                      Covered call strategies
                    </span>
                  </div>
              </div>
            </li>
            <li>
              <div className="d-flex align-items-center">
              <label className="custom-toggle custom-toggle-success mr-1">
                    <input type="checkbox" checked={subOptions[3]} onChange={(e) => updateSubs(3)}/>
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <div className="text-left">
                    <span className="pl-2 text-white">
                    Options Order Flow
                    <div className="text-success ml-2 mt-2">$35/month <span className="text-through text-light">$50/month</span></div>
                    </span>
                  </div>
              </div>
            </li>
          </ul>
        );
    }, [subOptions]);

    const Subtotal = React.useCallback(() => {
        return (
            <>
                <div className="display-2 text-white">${subtotal}</div>
                { subtotal === 75 ? <div className="text-through text-white">original price $84</div> : <></>}
            </>
        );
    }, [subtotal]);


    const submitSubscription = React.useCallback(async () => {
        const items = selectedProducts.map((item) => {
            return { "price": item.id }
        });
        const result = await createSubscription({
            customer_id,
            items: items
        });
        db.collection('users').doc(user.uid).collection('subscriptions').doc(result.id).set({id: result.id});
        setAlert(
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                confirmBtnText="Awesome"
                btnSize=""
            >
                <div>You can enjoy your 7 days free trial now.</div>
            </ReactBSAlert>
        );
    }, [customer_id, selectedProducts]);

    const updateSubscription = () => {
        const additionalChargeForCurrentMonth = fee < subtotal ? 
            (<>
                You will be charged additional ${subtotal - fee} today.
            </>) : 
            (<></>);
        const AlertContent = userPayments.length ? (
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Acknowledge"
                onConfirm={submitSubscription}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                confirmBtnText="Authorize"
                btnSize=""
            >
                {additionalChargeForCurrentMonth}
                <div>You will be charged ${subtotal} starting on {dateToCharge}.</div>
            </ReactBSAlert>
        ) : (
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Add your credit card info first."
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Add Credit Card"
                btnSize=""
            >
                <div>Please add your credit card info first.</div>
            </ReactBSAlert>
        )
        setAlert(AlertContent);
    };

    return (
        <>
            { subscriptionUpdateAlert }
            <Card className="card-pricing bg-gradient-primary border-0 mb-4">
            <CardHeader className="bg-transparent text-center">
            <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                Current Subscription
            </h4>
            </CardHeader>
            <CardBody className="px-lg-12 text-center">
            <Subtotal />
            <div className="text-white">per month</div>
            <div className="text-white">You will be charged on {periodEndOn} </div>
                <RenderSubOptions />
            <Button className="mb-3" color="info" type="button" disabled={fee===subtotal} onClick={updateSubscription}>
                Update Subscription
            </Button>
            </CardBody>
        </Card>
      </>
    );
}