/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { signInWithGoogle, auth, db, firestore, FBProvider } from "services/firebase";
import { connect } from 'react-redux';
import { updateUser } from "reducers/EasyOptions/user";
import { Redirect } from "react-router-dom";
import { createStripeCustomer } from 'actions/payments';
import { getSubscription } from "actions/payments";
import { getSubscription as getSubReducer } from "reducers/EasyOptions/user";
import { getTiers as getTiersReducer } from "reducers/EasyOptions/user";

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://www.easyoptions.app/admin/strategies',
  // This must be true.
  handleCodeInApp: true
};

function Login(props) {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [inputEmail, setInputEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const {
    user,
    updateUser,
    getSub,
    getTiers
  } = props;

  const retrieveSubscription = async (subscription_id) => {
    const result = await getSubscription({subscription_id});
    if (result) {
      // MORE: active status
      if (result.status === "trialing" || result.status === "active") {
          const data = result.items.data;
          // update subscriptions
          getSub({subscription: result});
          // get tiers from subscription data
          let subs = [];
          // TODO: find a better way
          data.forEach(item => {
            if (item.plan.amount === 800) {
              subs.push(2);
            } else if (item.plan.amount === 3500) {
              subs.push(3);
            } else if (item.plan.amount === 4300) {
              subs = [2, 3];
            }
          });
          getTiers({tiers: subs});
      } else {
        getTiers({ tiers: [0]});
      }
      
    }
  };

  const lookupUserFromDatabase = async (user) => {
    if (user) {
      const userRef = db.collection('users').doc(user.uid);
      userRef.onSnapshot((snapshot) => {
        if (snapshot.data()) {
          const userData = snapshot.data();
          // WRONG!!
          if (user.uid) {
            db.collection('users').doc(user.uid).collection('subscriptions').get()
            .then((subSnapshot) => {
              const subs = subSnapshot.docs.map((doc) => doc.data());
              if (subs && subs.length) {
                retrieveSubscription(subs[0].id);
              }
              updateUser({user: userData});
            });
          }
        } else {
          const userData = {
            displayName: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            created: firestore.FieldValue.serverTimestamp(),
            tier: 0,
            uid: user.uid
          };
          db.collection('users').doc(user.uid).set(userData).then(() => {
            updateUser({user: userData});
            createStripeCustomer({
              email: userData.email,
              uid: userData.uid
            });
          })
        }
      });
    }
  }

  React.useEffect(() => {
    if (!user || !user.email) {
      // Google Auth listener;
      auth.onAuthStateChanged(async (user) => {
        lookupUserFromDatabase(user);
      });
    }
  }, []);

  if (user && user.email) {
    return (<Redirect from="*" to="/admin/strategies" />);
  }

  const signInWithEmail = () => {
    setErrorMessage('');
    auth.signInWithEmailAndPassword(inputEmail, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      lookupUserFromDatabase(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const message = error.message;
      setErrorMessage(message);
    });
  }

  const signInWithFacebook = () => {
    setErrorMessage('');
    auth.signInWithPopup(FBProvider)
    .then((result) => {
      const user = result.user;
      lookupUserFromDatabase(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const message = error.message;
      setErrorMessage(message);
    });
  }

  return (
    <>
      <AuthHeader
        title="Welcome back!"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>
                <div className="text-muted font-italic">
                    <small>
                      {
                        errorMessage ? (
                          <span className="text-danger font-weight-700">
                            {errorMessage}
                          </span>
                        ) : (<></>)
                      }
                    </small>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={signInWithFacebook}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/facebook.png").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Facebook</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={signInWithGoogle}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Or sign in with credentials</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="button" onClick={signInWithEmail}>
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.EasyOptions.user
});

const mapDispatchToProps = dispatch => ({
  updateUser: ({user}) => dispatch(updateUser({user})),
  getSub: ({subscription}) => dispatch(getSubReducer({subscription})),
  getTiers: ({tiers}) => dispatch(getTiersReducer({tiers}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
