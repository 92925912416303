import React from "react";
import {
    FormGroup,
    Col,
    Input,
    Row,
    Button
  } from "reactstrap";

export function QueryRow({
    querySymbols,
    fromDate,
    endDate,
    setEndDate,
    setFromDate,
    setIsQuery,
    setQuerySymbols,
    isQuery
}) {
    const onSymbolsChange = (e) => {
        if (isQuery) {
            setIsQuery(false);
        }
        const string = e.target.value.replace(' ', '');
        setQuerySymbols(string.toUpperCase());
    }

    const onFromDate = (e) => {
        if (isQuery) {
            setIsQuery(false);
        }
        setFromDate(e.target.value);
    }

    const onEndDate = (e) => {
                if (isQuery) {
            setIsQuery(false);
        }
        setEndDate(e.target.value);
    }

    const onClear = () => {
        setQuerySymbols("");
        setFromDate("");
        setEndDate("");
        setIsQuery(false);
    }

    const onSearch = () => {
        setIsQuery(true);
    }

    return (
        <Row>
            <Col md="3">
                <FormGroup>
                    <label
                        className="form-control-label text-light"
                        htmlFor="symbolInput"
                        >
                        Symbols
                    </label>
                    <Input
                        id="symbolInput"
                        placeholder="Ex.AAPL, MSFT, SPY"
                        type="text"
                        value={querySymbols}
                        onChange={onSymbolsChange}
                    />
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <label
                        className="form-control-label text-light"
                        htmlFor="fromDate"
                        >
                        From Date
                    </label>
                    <Input
                        id="fromDate"
                        placeholder="2021-05-20"
                        type="text"
                        value={fromDate}
                        onChange={onFromDate}
                    />
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <label
                        className="form-control-label text-light"
                        htmlFor="endDate"
                        >
                        End Date
                    </label>
                    <Input
                        id="endDate"
                        placeholder="2021-05-21"
                        type="text"
                        value={endDate}
                        onChange={onEndDate}
                    />
                </FormGroup>
            </Col>
            <Col md="3" className="row align-items-center mt-1">
                    <Button
                        className="btn-primary"
                        color=""
                        href="#pablo"
                        onClick={onSearch}
                    >
                        Search
                    </Button>
                    <Button
                        className="btn-default"
                        color=""
                        href="#pablo"
                        onClick={onClear}
                    >
                        clear
                    </Button>
            </Col>
        </Row>
    );
}