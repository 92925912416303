/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { getTickerID, getDailyData } from "actions/webull";
import DailyStonks from "./dailyStonks/DailyStonks";

const buyInStocks = [
    {
        ticker: "VCLT",
        closePrice: 99.33,
        buyInRange: "$90~$95",
        exitRange: "$100~$105",
        exitProfits: "10%~15%",
        date: "03/21/2021"
    },
    {
        ticker: "NKE",
        closePrice: 137.49,
        buyInRange: "$125~$132",
        exitRange: "< $160",
        exitProfits: "13%~18%",
        date: "03/21/2021"
    }
];

function Main(props) {
    const [stocks, setStocks] = React.useState([]);
    const [coveredCalls, setCoveredCalls] = React.useState([]);

    const {
        user,
        tiers
    } = props;

  const updateLatestStockData = async () => {
        const updateData = [];
        for(let i=0; i < buyInStocks.length; i++) {
            const stock = buyInStocks[i];
            const webullTickerInfo = await getTickerID({ ticker: stock.ticker }); 
            const webullTickerId = await webullTickerInfo && webullTickerInfo.data && webullTickerInfo.data[0].tickerId;
            const companyName = await webullTickerInfo && webullTickerInfo.data && webullTickerInfo.data[0].name;
            if (webullTickerId) {
                const tickerPriceData = await getDailyData({ tickerId: webullTickerId, type: 'd1', count: 500 });
                const rawChartDataSet = await tickerPriceData && tickerPriceData[0].data;
                const openPrice = parseFloat(rawChartDataSet[0].split(",")[1]); // open price for the latest day.
                const closePrice = parseFloat(rawChartDataSet[0].split(",")[2]); // close price for the latest day.
                const changePercent = (parseFloat((closePrice - openPrice) / openPrice) * 100).toFixed(2);
                const changePercentSinceAnnounced = (parseFloat((closePrice - stock.closePrice) / stock.closePrice) * 100).toFixed(2);
                updateData.push({
                    symbol: stock.ticker,
                    latestPrice: closePrice,
                    changePercent,
                    name: companyName,
                    changePercentSinceAnnounced,
                    ...stock
                });
            }
        }
        setStocks(updateData);
  }

  React.useEffect(() => {
    // if (tiers.indexOf(1) > -1) {
      updateLatestStockData();
    // }
  }, [tiers]);

  const BlueChipsRecommendations = React.useCallback(() => {
    return <CardsHeader name="Main" stocks={stocks} parentName="Main"/>
  }, [stocks]);

  if (!user || !user.email) {
    return (<Redirect from="*" to="/auth/login" />);
  }

  return (
    <>
      <BlueChipsRecommendations />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            {/* { tiers.indexOf(2) > -1 ? (<DailyStonks />) : (<></>) } */}
            <DailyStonks />
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.EasyOptions.user,
  subscription: state.EasyOptions.subscription,
  tiers: state.EasyOptions.tiers
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);