/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  NavLink
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
import { strings } from './locales/index';
import { connect } from 'react-redux';

function Index(props) {
  const {
    locale
  } = props;

  React.useEffect(() => {
    strings.setLanguage(locale);
  }, [locale]);

  return (
    <>
      <IndexNavbar />
      <div className="main-content">
        <IndexHeader />
        <section className="py-6 pb-9 bg-info">
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h2 className="display-3 text-white">
                  { strings.homepageSubtitle }
                </h2>
                <p className="lead text-white">
                  
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0 bg-gradient-default">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                          <i className="ni ni-check-bold" />
                        </div>
                        <span className="ml-4 h1 text-info text-uppercase">
                          { strings.easyOptionsStrategies}
                        </span>
                        <p className="description mt-3 text-white">
                          { strings.strategyDescription}
                        </p>
                        <div className="d-flex justify-space-between align-items-center">
                            <Badge color="info" pill>
                              profitable
                            </Badge>
                            <Badge color="info" pill>
                              confident
                            </Badge>
                            <Badge color="info" pill>
                              Less risky
                            </Badge>
                            <Badge color="info" pill>
                              Hands-on
                            </Badge>
                            <div className="ml-3">
                              <Badge color="success" >
                                <NavLink
                                    to="/auth/pricing"
                                    className="text-white"
                                    tag={Link}
                                >
                                    Sign up
                                </NavLink>
                              </Badge>
                            </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="card-lift--hover shadow border-0 bg-gradient-default">
                      <CardBody className="py-5">
                        <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                          <i className="ni ni-planet" />
                        </div>
                        <span className="ml-4 h1 text-warning text-uppercase">
                          Easy Options Data
                        </span>
                        <p className="description mt-3 text-white">
                          Real-time options orders flow lets you follow the smart money.
                          It provides confidence for you to place stocks / options orders.
                        </p>
                        <div className="d-flex justify-space-between align-items-center">
                          <>
                            <Badge color="warning" pill>
                              Real-time
                            </Badge>
                            <Badge color="warning" pill>
                              Affordable
                            </Badge>
                            <Badge color="warning" pill>
                              Profitable
                            </Badge>
                            <Badge color="warning" pill>
                              Smart Money
                            </Badge>
                          </>
                          <div className="ml-3">
                            <Badge color="success" >
                              <NavLink
                                    to="/auth/pricing"
                                    className="text-white"
                                    tag={Link}
                                >
                                    Sign up
                                </NavLink>
                            </Badge>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/blueChips.png").default}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h1>Buy The Dips</h1>
                  <p>
                    Our machine learning models filter out the blue chips for you when they are dippped.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-settings-gear-65" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Machine Learning Models</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Blue Chips</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Profits with safe margin in mind</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/dailyStonks.png").default}
                />
              </Col>
              <Col md="6">
                <div className="pr-md-5">
                  <h1>Daily Stonks</h1>
                  <p>
                    Want to find the next $GME? Our algorithm find daily potential GME for you.
                  </p>
                  <ul className="list-unstyled mt-5">
                  <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="warning">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Risky</h4>
                        </div>
                      </div>
                    </li>
                  <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="info">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Meme Stocks</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Next $GME</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/coveredCalls.png").default}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h1>Covered Call Strateiges</h1>
                  <p>
                    Our machine learning models find the most stable, risk-avert, and profitable covered call strategies for you daily.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="success">
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">Passive Weekly Incomes</h4>
                          </div>
                        </div>
                      </li>
                    <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle mr-3" color="info">
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">Hands-on instruction</h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Machine learning models</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/theme/optionsFlow.png").default}
                />
              </Col>
              <Col md="6">
                <div className="pr-md-5">
                  <h1>Options Flow</h1>
                  <p>
                    Options Flow alerts you as notable orders take place on the stock and equity options markets. Remove the noise and zone in on what truly matters.
                  </p>
                  <ul className="list-unstyled mt-5">
                  <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="warning">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">$33/month (pre-subscribe)</h4>
                        </div>
                      </div>
                    </li>
                  <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="info">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Cheaper than competitors</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="success">
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h4 className="mb-0">Right tool for success</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <AuthFooter />
    </>
  );
}

const mapStateToProps = state => ({
  locale: state.EasyOptions.locale
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
