/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { signInWithGoogle, auth, db, firestore, FBProvider } from "services/firebase";
import { connect } from 'react-redux';
import { updateUser } from "reducers/EasyOptions/user";
import { Redirect } from "react-router-dom";

function Register(props) {
  const [focusedName, setfocusedName] = React.useState(false);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [inputEmail, setInputEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');

  const {
    user,
    updateUser
  } = props;

  const addUserToDatabase = (user) => {
    const userData = {
      displayName,
      email: user.email,
      created: firestore.FieldValue.serverTimestamp(),
      tier: 0,
      uid: user.uid
    };
    db.collection('users').doc(user.uid).set(userData).then(() => {
      updateUser({
        user: userData
      });
    });
  }

  React.useEffect(() => {
    if (user !== null && user.hasOwnProperty('email')) {
      auth.onAuthStateChanged(async (user) => {
        addUserToDatabase(user);
      });
    }
  }, []);

  if (user && user.email) {
    return (<Redirect from="*" to="/admin/strategies" />);
  }

  const submitRegister = () => {
    setErrorMessage('');
    auth.createUserWithEmailAndPassword(inputEmail, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      addUserToDatabase(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const message = error.message;
      setErrorMessage(message);
    });
  }

  const signInWithFacebook = () => {
    setErrorMessage('');
    auth.signInWithPopup(FBProvider)
    .then((result) => {
      const user = result.user;
      addUserToDatabase(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const message = error.message;
      setErrorMessage(message);
    });
  }

  return (
    <>
      <AuthHeader
        title="Create an account"
        lead="Sign up with Easy Options to get machine learning models predicted buy-in & sell-off signals"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-4">
                  <small>Sign up with</small>
                </div>
                <div className="text-center">
                  <Button
                    className="btn-neutral btn-icon mr-4"
                    color="default"
                    href="#pablo"
                    onClick={signInWithFacebook}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/facebook.png").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Facebook</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={signInWithGoogle}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Or sign up with credentials</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        type="text"
                        onFocus={() => setfocusedName(true)}
                        onBlur={() => setfocusedName(false)}
                        value={displayName}
                        onChange={(e) => setDisplayName(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-muted font-italic">
                    <small>
                      {
                        errorMessage ? (
                          <span className="text-danger font-weight-700">
                            {errorMessage}
                          </span>
                        ) : (<></>)
                      }
                    </small>
                  </div>
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree with the{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Privacy Policy
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button className="mt-4" color="info" type="button" onClick={submitRegister}>
                      Create account
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.EasyOptions.user
});

const mapDispatchToProps = dispatch => ({
  updateUser: ({user}) => dispatch(updateUser({user}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
