/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Easy Options (https://www.creative-tim.com)

* Coded by Easy Options

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/pages/examples/Login.js";
import Setting from "views/pages/setting/Setting.js";
import Pricing from "views/pages/examples/Pricing.js";
import Register from "views/pages/examples/Register.js";
import OptionsFlow from "views/pages/dashboards/OptionsFlow";
import Main from "views/pages/dashboards/Main";
import CoveredCalls from "views/pages/dashboards/CoveredCalls";
import AboutUS from "views/pages/examples/AboutUs.js";

const routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/strategies",
        name: "Covered Call Strategies",
        miniName: "C",
        component: CoveredCalls,
        layout: "/admin",
      },
      {
        path: "/optionsFlow",
        name: "OptionsFlow",
        miniName: "O",
        component: OptionsFlow,
        layout: "/admin",
      },
      {
        path: "/stonks",
        name: "Stonks",
        miniName: "M",
        component: Main,
        layout: "/admin",
      }
    ],
  },
  {
    path: "/settings",
    name: "Payment",
    miniName: "P",
    component: Setting,
    layout: "/admin",
    icon: "ni ni-archive-2 text-green"
  },
  {
    path: "/aboutus",
    name: "About us",
    sidebar: false,
    state: "authCollapse",
    component: AboutUS,
    layout: "/auth"
  },
  {
    collapse: true,
    icon: "ni ni-ungroup text-orange",
    sidebar: false,
    state: "authCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      }
    ],
  }
];

export default routes;
