import { UPDATE_USER, GET_SUBSCRIPTION, GET_TIERS } from "./user";
const GET_LOCALE = 'GET_LOCALE';

export const setLocale = ({locale}) => ({
    type: GET_LOCALE,
    locale
});

export default function reducer(
    state = {
        user: {},
        subscription: {},
        tiers: [0],
        locale: 'en'
    },
    action
) {
    switch (action.type) {
    case UPDATE_USER:
        return {
            ...state,
            user: action.user
        };
    case GET_SUBSCRIPTION:
        return {
            ...state,
            subscription: action.subscription
        }
    case GET_TIERS:
        return {
            ...state,
            tiers: action.tiers
        }
    case GET_LOCALE:
        return {
            ...state,
            locale: action.locale
        }
    default:
        break;
    }
    return state;
}