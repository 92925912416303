/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Card,
  CardHeader,
  // DropdownMenu,
  // DropdownItem,
  // DropdownToggle,
  // UncontrolledDropdown,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import { getLiveOptionsFlow } from "reducers/Benzinga/liveOptionsFlow";
import { getLiveOptionsActivities } from "actions/Benzinga";
import NewActivitiesCells from './NewFeeds';
import { TransitionGroup } from 'react-transition-group';
import { QueryRow } from "../optionsFlow/QueryRow";
import { getPutCallStats, optionActivityCell } from "../optionsFlow/OptionActivityCell";
import OptionsFlowHeader from "components/Headers/OptionsFlowHeader";

function OptionsFlowLiveTable(props) {
    const {
        option_activity: optionsActivities,
        last_updated_timestamp = 0,
        getLiveOptionsActivities: updateLiveOptionsActivites,
    } = props;
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(last_updated_timestamp);
    const optionsFlowTable = useRef(null);

    /**
     * Query Row 
     */
    const [querySymbols, setQuerySymbols] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isQuery, setIsQuery] = useState(false);
    const [queryActivities, setQueryActivities] = useState([]);

    /**
     * 
     * Header Calculations
     */
    const [bullishRate, setBullishRate] = useState(0);
    const [putCallRatio, setPutCallRatio] = useState(0);
    const [putFlow, setPutFlow] = useState(0);
    const [callFlow, setCallFlow] = useState(0);
    const [callDollarAmount, setCallDollarAmount] = useState(0);
    const [putDollarAmount, setPutDollarAmount] = useState(0);

    const processOptionsActivities = (data) => {
      let updated_timestamp = 0;
      if (data.option_activity.length) {
        const editedData = data.option_activity;
        if (editedData && editedData.length) {
          updated_timestamp = data.option_activity[0].updated;
        }
        if (!isQuery) {
          setLastUpdatedTimestamp(updated_timestamp);
          updateLiveOptionsActivites(editedData, updated_timestamp);
        } else {
          setQueryActivities(editedData);
        }
      }
    }

    const getLiveData = async () => {
        const data = await getLiveOptionsActivities({lastUpdatedTimestamp});
        processOptionsActivities(data);
        setHasFetchedData(true);
    }

    const getQueryData = async () => {
      const data = await getLiveOptionsActivities({endDate, fromDate, querySymbols });
      processOptionsActivities(data);
    }

    React.useEffect(() => {
        if (!hasFetchedData) {
            getLiveData();
        }
    }, []);

    React.useEffect(() => {
      if (isQuery) {
        getQueryData();
      }
    }, [isQuery, querySymbols, fromDate, endDate]);

    React.useEffect(() => {
        if (hasFetchedData && !isQuery) {
            new List(optionsFlowTable.current, {
                valueNames: ["time"],
                listClass: "list",
            });
        }
    }, [hasFetchedData, isQuery]);

    const getStatsForHeader = (activities) => {
      const {
        sumCalls,
        sumBullish,
        totalCallSize,
        totalPutSize,
        sumCallDollarAmount,
        sumPutDollarAmount
      } = getPutCallStats(activities);
      setBullishRate((sumBullish/activities.length));
      setCallFlow(totalCallSize);
      setPutFlow(totalPutSize);
      setPutCallRatio((sumCalls/activities.length).toFixed(2));
      setCallDollarAmount(sumCallDollarAmount);
      setPutDollarAmount(sumPutDollarAmount);
    }

    const OptionActivityCells = React.useCallback(() => {
        if (!isQuery && optionsActivities && optionsActivities.length) {
            getStatsForHeader(optionsActivities);
            return optionsActivities.map((activity) => {
                return optionActivityCell(activity);
            });
        }
        if (isQuery && queryActivities && queryActivities.length) {
          getStatsForHeader(queryActivities);
          return queryActivities.map((activity) => {
            return optionActivityCell(activity);
        });
        }
        return null;
    }, [isQuery, hasFetchedData, optionsActivities, queryActivities])

    const RenderNewActivities = React.useCallback(() => {
      if (hasFetchedData && !isQuery) {
        return (<NewActivitiesCells />);
      } else {
        return null;
      }
    }, [hasFetchedData, isQuery]);

  return (
    <>
      <OptionsFlowHeader
        name="Options Flow"
        parentName="Options Flow"
        callDollarAmount={callDollarAmount}
        bullishRate={bullishRate}
        putCallRatio={putCallRatio}
        putDollarAmount={putDollarAmount}
        callFlow={callFlow}
        putFlow={putFlow}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Options Flow</h3>
                <QueryRow 
                  querySymbols={querySymbols}
                  fromDate={fromDate}
                  endDate={endDate}
                  setFromDate={setFromDate}
                  setEndDate={setEndDate}
                  setQuerySymbols={setQuerySymbols}
                  setIsQuery={setIsQuery}
                  isQuery={isQuery}
                />
              </CardHeader>
              <div className="table-responsive" ref={optionsFlowTable}>
                <Table className="align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" data-sort="time">
                        Time
                      </th>
                      <th scope="col">
                        Tick
                      </th>
                      <th scope="col">
                        Expiry
                      </th>
                      <th scope="col">
                        Strike
                      </th>
                      <th scope="col">C/P</th>
                      <th scope="col">Size</th>
                      <th scope="col">Price</th>
                      <th scope="col">Premium</th>
                      <th scope="col">Type</th>
                      <th scope="col">Volume</th>
                      <th scope="col">OI</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                      <TransitionGroup
                        component={null}
                      >
                        <RenderNewActivities />
                      </TransitionGroup>
                      <OptionActivityCells />
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
    option_activity: state.Benzinga.option_activity
  });
  
const mapDispatchToProps = dispatch => ({
    getLiveOptionsActivities: (option_activity, last_updated_timestamp) => dispatch(getLiveOptionsFlow({option_activity, last_updated_timestamp})),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(OptionsFlowLiveTable);
