import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Row
} from "reactstrap";

import { connect } from 'react-redux';
import { getTickerID, getDailyData } from "actions/webull";
import { getStonksFromAirtable } from "actions/airtable";

function DailyStonks(props) {
    const [stocks, setStocks] = React.useState([]);
    const [dailyStonks, setDailyStonks] = React.useState([]);
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;

    const getDailyStonks = async () => {
      let stonks = await getStonksFromAirtable({ date: formattedDate });
      setDailyStonks(stonks);
    }
    React.useEffect(() => {
      getDailyStonks();
    }, []);

    const updateLatestStockData = async () => {
        const updateData = [];
        for(let i=0; i < dailyStonks.length; i++) {
            const symbol = dailyStonks[i];
            const webullTickerInfo = await getTickerID({ ticker: symbol}); 
            const webullTickerId = await webullTickerInfo && webullTickerInfo.data && webullTickerInfo.data[0].tickerId;
            const companyName = await webullTickerInfo && webullTickerInfo.data && webullTickerInfo.data[0].name;
            if (webullTickerId) {
                const tickerPriceData = await getDailyData({ tickerId: webullTickerId, type: 'd1', count: 2 });
                const rawChartDataSet = await tickerPriceData && tickerPriceData[0].data;
                const openPrice = parseFloat(rawChartDataSet[0].split(",")[1]); // open price for the latest day.
                const closePrice = parseFloat(rawChartDataSet[0].split(",")[2]); // close price for the latest day.
                const changePercent = (parseFloat((closePrice - openPrice) / openPrice) * 100).toFixed(2);
                updateData.push({
                    symbol: symbol,
                    latestPrice: closePrice,
                    changePercent,
                    name: companyName
                });
            }
        }
        setStocks(updateData);
    }

    React.useEffect(() => {
      if (dailyStonks && dailyStonks.length) {
        updateLatestStockData();
      }
    }, [dailyStonks]);

    const StonkData = React.useCallback(() => {
        return stocks.map((stock, index) => {
            const priceClass = stock.changePercent >= 0 ? "text-success" : "text-danger";
            const textClass = stock.changePercent >= 0 ? "fas fa-arrow-up" : "fas fa-arrow-down";
            return (
                <tr>
                    <th scope="row" className="font-bold-text text-success">{stock.symbol}</th>
                    <td>{stock.name}</td>
                    <td><span className={`${priceClass} h4`}>${stock.latestPrice}</span></td>
                    <td>
                    <i className={`${textClass} ${priceClass} mr-3`} />
                        <span className={`${priceClass} h4`}>{stock.changePercent} % </span>
                    </td>
                </tr>
            );
        })
    }, [stocks]);

    return (
        <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">Daily Stonks {formattedDate}</h3>
            </div>
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Symbol</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">% Change</th>
            </tr>
          </thead>
          <tbody>
            <StonkData />
          </tbody>
        </Table>
      </Card>
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyStonks);