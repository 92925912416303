// const BENZINGA_HOST = 'http://localhost:5001/easy-options-dev/us-central1';
const BENZINGA_HOST = 'https://us-central1-easy-options-dev.cloudfunctions.net';

export const getLiveOptionsActivities = async ({ lastUpdatedTimestamp, fromDate, endDate, querySymbols }) => {
    let endpoint = `${BENZINGA_HOST}/optionsFlow?`;
    let isNotFirstParam = false;
    if (lastUpdatedTimestamp) {
        isNotFirstParam = true;
        endpoint += `parameters%5Bupdated%5D=${lastUpdatedTimestamp}`;
    }
    if (fromDate) {
        if (isNotFirstParam) {
            endpoint += `&`;
        } else {
            isNotFirstParam = true;
        }
        endpoint += `parameters%5Bdate_from%5D=${fromDate}`;
    }
    if (endDate) {
        if (isNotFirstParam) {
            endpoint += `&`;
        } else {
            isNotFirstParam = true;
        }
        endpoint += `parameters%5Bdate%5D=${endDate}`;
    }
    if (querySymbols) {
        if (isNotFirstParam) {
            endpoint += `&`;
        } else {
            isNotFirstParam = true;
        }
        endpoint += `parameters%5Btickers%5D=${querySymbols}`;
    }
    const response = await fetch(`${endpoint}`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        },
    });
    if (response && response.status == 200) {
        return response.json();
    } else {
        // TODO: error message
    }
}