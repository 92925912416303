import React from "react";
import { CSSTransition } from 'react-transition-group';
import currency from 'currency.js';

const stringToDollarFormatter = (price) => {
    return String(currency(parseFloat(price), { separator: ',' }).format());
}

export function optionActivityCell(activity) {
    const textColor = activity.sentiment === "BULLISH" ? "easyoptions-green" : "stock-red";
    const infoTextColor = activity.option_activity_type === "SWEEP" ? "text-warning" : "easyoptions-green";
    const putCallTextColor = activity.put_call === "PUT" ? "stock-red" : "easyoptions-green";
    return (
        <CSSTransition
        key={activity.id}
        classNames="item"
        timeout={500}
        in={true}
        >
            <tr key={activity.id}>
            <td className="budget">{activity.time}</td>
            <td className={`budget ${textColor} font-weight-bold`}>{activity.ticker}</td>
            <td className="budget">{activity.date_expiration}</td>
            <td className="budget">{stringToDollarFormatter(activity.strike_price)}</td>
            <td className={`budget ${putCallTextColor}`}>{activity.put_call}</td>
            <td className="budget">{activity.size}</td>
            <td className="budget">{stringToDollarFormatter(activity.price)}</td>
            <td className={`budget ${textColor} font-weight-bold`}>{stringToDollarFormatter(activity.cost_basis)}</td>
            <td className={`budget ${infoTextColor} font-weight-bold`}>{activity.option_activity_type}</td>
            <td className="budget">{activity.volume}</td>
            <td className="budget">{activity.open_interest}</td>
            {/* <td className="text-right">
            <UncontrolledDropdown>
                <DropdownToggle
                className="btn-icon-only text-light"
                color=""
                role="button"
                size="sm"
                >
                <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                    Action
                </DropdownItem>
                <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                    Another action
                </DropdownItem>
                <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                    Something else here
                </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            </td> */}
        </tr>
        </CSSTransition>
    );
}

export function getPutCallStats(activities) {
    let sumCalls = 0;
    let sumBullish = 0;
    let totalCallSize = 0;
    let totalPutSize = 0;
    let sumPutDollarAmount = 0;
    let sumCallDollarAmount = 0;
    const total = activities.length;
    for(let i=0; i < total; i++) {
        if (activities[i].sentiment === "BULLISH") {
            sumBullish += 1;
        }
        const size = parseInt(activities[i].size);
        const price = parseFloat(activities[i].price).toFixed(2);
        if (activities[i].put_call === "CALL") {
            sumCalls += 1;
            totalCallSize += size;
            sumCallDollarAmount += size * price;
        } else {
            totalPutSize += size;
            sumPutDollarAmount += size * price;
        }
    }
    return {
        sumCalls,
        sumBullish,
        totalCallSize,
        totalPutSize,
        sumCallDollarAmount,
        sumPutDollarAmount
    }
}